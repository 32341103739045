import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiEndpoint } from '../utils/api';
import WelcomeStep from './onboarding/WelcomeStep';
import FeaturesStep from './onboarding/FeaturesStep';
import ReviewsStep from './onboarding/ReviewsStep';
import TemplatesStep from './onboarding/TemplatesStep';
import LoadingStep from './onboarding/LoadingStep';
import SubscriptionModal from './SubscriptionModal';

const OnboardingFlow = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubscription, setShowSubscription] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const navigate = useNavigate();

  const totalSteps = 4;

  useEffect(() => {
    // Log when component mounts to confirm it's being rendered
    console.log('OnboardingFlow component mounted');
    
    // Tell parent App component to hide the header and footer
    document.body.classList.add('onboarding-active');
    
    // Debug token and newUser flag
    const token = localStorage.getItem('token');
    const newUserFlag = localStorage.getItem('newUser');
    
    console.log('OnboardingFlow - Token in localStorage:', !!token);
    console.log('OnboardingFlow - newUser flag in localStorage:', newUserFlag);
    
    // Verify we're a new user
    if (newUserFlag !== 'true') {
      console.log('OnboardingFlow - User is not marked as new, setting flag to be safe');
      localStorage.setItem('newUser', 'true');
    }
    
    setIsNewUser(true);
    
    // Debug environment and API URL
    console.log('OnboardingFlow - Environment:', process.env.NODE_ENV);
    console.log('OnboardingFlow - API URL:', window.REACT_APP_API_URL);
    
    // If user tries to refresh or navigate away, show a confirmation
    window.onbeforeunload = () => {
      return "Are you sure you want to leave? Your onboarding progress will be lost.";
    };

    // Ensure we stay in the onboarding flow
    const preventRedirect = () => {
      if (!document.body.classList.contains('onboarding-active')) {
        console.log('OnboardingFlow - Body class was removed, re-adding...');
        document.body.classList.add('onboarding-active');
      }
    };
    
    // Check every second to ensure onboarding remains active
    const interval = setInterval(preventRedirect, 1000);

    return () => {
      // Clean up when component unmounts
      console.log('OnboardingFlow component unmounting');
      document.body.classList.remove('onboarding-active');
      window.onbeforeunload = null;
      clearInterval(interval);
    };
  }, []);

  const saveFavoriteTemplates = async () => {
    try {
      // Don't save if no templates were selected
      if (selectedTemplates.length === 0) {
        console.log('OnboardingFlow - No templates selected, skipping save');
        return;
      }

      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No authentication token found');
        return;
      }

      console.log('OnboardingFlow - Saving favorite templates:', selectedTemplates);
      
      const response = await axios.post(
        getApiEndpoint('save-favorite-templates'),
        { templateIds: selectedTemplates },
        { headers: { Authorization: `Bearer ${token}` }}
      );
      
      console.log('OnboardingFlow - Templates saved successfully:', response.data);
      setSaveError(null);
    } catch (error) {
      console.error('OnboardingFlow - Error saving templates:', error);
      setSaveError('Failed to save template preferences, but you can set them later.');
      // Continue with the flow even if saving fails
    }
  };

  const handleNext = () => {
    console.log('OnboardingFlow - Moving to next step', currentStep + 1);
    if (currentStep < totalSteps) {
      setCurrentStep(prev => prev + 1);
    } else {
      // Final step - save templates and show loading
      console.log('OnboardingFlow - Final step reached, showing loading');
      setIsLoading(true);
      
      // Save selected templates before showing subscription
      saveFavoriteTemplates().then(() => {
        // Simulate setup process
        setTimeout(() => {
          console.log('OnboardingFlow - Loading complete, showing subscription');
          setIsLoading(false);
          setShowSubscription(true);
        }, 3000);
      });
    }
  };

  const handleBack = () => {
    console.log('OnboardingFlow - Moving back to previous step', currentStep - 1);
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleSkip = () => {
    console.log('OnboardingFlow - User chose to skip onboarding steps and go to subscription');
    // Save any templates that might have been selected before skipping
    saveFavoriteTemplates().then(() => {
      // Show a brief loading state
      setIsLoading(true);
      
      // Short timeout to simulate setup process
      setTimeout(() => {
        setIsLoading(false);
        // Skip directly to subscription modal
        setShowSubscription(true);
      }, 1000);
    });
    
    // Clear new user flag
    localStorage.removeItem('newUser');
  };

  const handleTemplateSelect = (template) => {
    console.log('OnboardingFlow - Template selected/deselected:', template);
    if (selectedTemplates.includes(template)) {
      setSelectedTemplates(selectedTemplates.filter(t => t !== template));
    } else {
      setSelectedTemplates([...selectedTemplates, template]);
    }
  };

  const handleSubscriptionClose = () => {
    console.log('OnboardingFlow - Subscription modal closed');
    // Clear new user flag
    localStorage.removeItem('newUser');
    setShowSubscription(false);
    navigate('/write');
  };

  // Render function - don't return early, render everything in one return statement
  return (
    <div className="fixed inset-0 z-[100] bg-gradient-to-r from-indigo-100 to-purple-100 flex flex-col justify-center items-center">
      {/* Display save error if any */}
      {saveError && (
        <div className="bg-amber-100 border-l-4 border-amber-500 text-amber-700 p-4 mb-4 fixed top-0 right-0 m-4 z-50">
          <p>{saveError}</p>
          <button 
            className="ml-2 text-amber-500 hover:text-amber-700" 
            onClick={() => setSaveError(null)}
          >
            Dismiss
          </button>
        </div>
      )}
      
      {/* Only one of these three should be visible at a time */}
      {!isLoading && !showSubscription && (
        <div className="w-full max-w-4xl p-8 mx-auto rounded-xl bg-white shadow-xl min-h-[70vh] flex flex-col">
          {/* Progress bar */}
          <div className="w-full h-2 bg-gray-200 rounded-full mb-8">
            <div 
              className="h-full bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full"
              style={{ width: `${(currentStep / totalSteps) * 100}%` }}
            ></div>
          </div>
          
          {/* Step content */}
          {currentStep === 1 && <WelcomeStep onNext={handleNext} onSkip={handleSkip} />}
          {currentStep === 2 && <FeaturesStep onNext={handleNext} onBack={handleBack} onSkip={handleSkip} />}
          {currentStep === 3 && <ReviewsStep onNext={handleNext} onBack={handleBack} onSkip={handleSkip} />}
          {currentStep === 4 && (
            <TemplatesStep 
              onNext={handleNext} 
              onBack={handleBack}
              onSkip={handleSkip}
              selectedTemplates={selectedTemplates}
              onTemplateSelect={handleTemplateSelect}
            />
          )}
        </div>
      )}
      
      {isLoading && <LoadingStep />}
      
      {showSubscription && (
        <div className="absolute inset-0 flex items-center justify-center">
          <SubscriptionModal 
            onClose={handleSubscriptionClose}
            onSubscriptionUpdate={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default OnboardingFlow; 