// Utility function for API endpoint generation
const getApiBaseUrl = () => {
  // First check window global which is set in index.html (guaranteed to be correct in production)
  // Then fallback to environment variable, then fallback to localhost
  const apiUrl = window.REACT_APP_API_URL || process.env.REACT_APP_API_URL || 'http://localhost/api';
  
  // Normalize the URL: ensure it doesn't end with /api to prevent double /api in endpoints
  return apiUrl.endsWith('/api') ? apiUrl.substring(0, apiUrl.length - 4) : apiUrl;
};

/**
 * Creates the correct API endpoint URL
 * @param {string} path - The API path without leading slash (e.g. "emails", "user-settings")
 * @returns {string} The complete API URL
 */
export const getApiEndpoint = (path) => {
  // Get the most current base URL (without /api suffix)
  const baseUrl = getApiBaseUrl();
  console.log('API base URL in utility:', baseUrl);
  
  // Always use the same format: baseUrl + /api/ + path
  const endpoint = `${baseUrl}/api/${path}`;
  
  console.log(`getApiEndpoint('${path}') => ${endpoint}`);
  return endpoint;
};

export default { getApiEndpoint }; 