import React from 'react';
import { Button } from './ui/button';
import axios from 'axios';
import { getApiEndpoint } from '../utils/api';

const Settings = ({ userSettings, setUserSettings, isSubscribed, isSubscriptionActive }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserSettings(prevSettings => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleCancelSubscription = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(getApiEndpoint('cancel-subscription'), {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.success) {
        alert('Subscription canceled successfully');
        setUserSettings(prevSettings => ({
          ...prevSettings,
          isSubscribed: false,
          isSubscriptionActive: false
        }));
      } else {
        alert(response.data.message || 'Failed to cancel subscription');
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      alert('Failed to cancel subscription');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post(getApiEndpoint('user-settings'), userSettings, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Settings saved successfully');
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Failed to save settings');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-pink-100 to-purple-100 min-h-screen">
      <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-6 text-indigo-800">Settings</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {[
              { name: 'name', label: 'Name' },
              { name: 'role', label: 'Role/Niche' },
              { name: 'company', label: 'Company' },
              { name: 'preferredLanguage', label: 'Preferred Language' },
              { name: 'instagramHandle', label: 'Instagram Handle' },
              { name: 'youtubeChannel', label: 'YouTube Channel' },
              { name: 'tiktokHandle', label: 'TikTok Handle' },
              { name: 'websiteUrl', label: 'Website/Blog URL' },
            ].map((field) => (
              <div key={field.name}>
                <label htmlFor={field.name} className="block text-sm font-medium text-indigo-700 mb-1">{field.label}</label>
                <input
                  type="text"
                  name={field.name}
                  id={field.name}
                  value={userSettings[field.name]}
                  onChange={handleChange}
                  className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-indigo-50"
                />
              </div>
            ))}
            <div>
              <label htmlFor="writingStyle" className="block text-sm font-medium text-indigo-700 mb-1">Writing Style</label>
              <select
                name="writingStyle"
                id="writingStyle"
                value={userSettings.writingStyle}
                onChange={handleChange}
                className="w-full p-2 bg-indigo-50 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none"
              >
                <option value="professional">Professional</option>
                <option value="casual">Casual</option>
                <option value="formal">Formal</option>
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="bio" className="block text-sm font-medium text-indigo-700 mb-1">Short Bio</label>
            <textarea
              name="bio"
              id="bio"
              value={userSettings.bio || ''}
              onChange={handleChange}
              rows={3}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-indigo-50"
            />
          </div>
          <div>
            <label htmlFor="signature" className="block text-sm font-medium text-indigo-700 mb-1">Email Signature</label>
            <textarea
              name="signature"
              id="signature"
              value={userSettings.signature || ''}
              onChange={handleChange}
              rows={4}
              className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-indigo-50"
            />
          </div>
          <button type="submit" className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors">
            Save Settings
          </button>
        </form>
        {isSubscribed && isSubscriptionActive && (
          <Button
            onClick={handleCancelSubscription}
            className="w-full bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition-colors mt-4"
          >
            Cancel Subscription
          </Button>
        )}
      </div>
    </div>
  );
};

export default Settings;
