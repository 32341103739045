import React from 'react';

const Terms = () => {
  return (
    <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-pink-100 to-purple-100 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6 text-indigo-800">Terms of Use</h1>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">1. Acceptance of Terms</h2>
          <p className="text-indigo-900 mb-3">
            By accessing and using InboxAI ("the Service"), you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to these terms, please do not use our service.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">2. Description of Service</h2>
          <p className="text-indigo-900 mb-3">
            InboxAI provides an AI-powered email writing and communication assistance tool. The Service utilizes artificial intelligence to help users draft, edit, and enhance their written communications.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">3. Subscription and Payments</h2>
          <p className="text-indigo-900 mb-3">
            Some features of InboxAI require a paid subscription. By subscribing to a premium plan, you agree to pay the subscription fees indicated at the time of purchase. Subscriptions automatically renew unless canceled before the renewal date.
          </p>
          <p className="text-indigo-900 mb-3">
            We use Stripe as our payment processor. Your payment information is handled securely according to industry standards and is not stored on our servers.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">4. Cancellation and Refunds</h2>
          <p className="text-indigo-900 mb-3">
            You may cancel your subscription at any time through your account settings. Upon cancellation, you will continue to have access to premium features until the end of your current billing period. We do not provide refunds for partial subscription periods.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">5. Intellectual Property</h2>
          <p className="text-indigo-900 mb-3">
            The content you generate using our Service belongs to you. However, InboxAI retains all rights, title, and interest in and to the Service, including all intellectual property rights.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">6. User Conduct</h2>
          <p className="text-indigo-900 mb-3">
            You agree not to use the Service for any unlawful purposes or to conduct any unlawful activity. This includes, but is not limited to, fraud, phishing, or sending spam or unsolicited messages.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">7. Limitation of Liability</h2>
          <p className="text-indigo-900 mb-3">
            InboxAI is provided "as is" without warranties of any kind. In no event shall InboxAI be liable for any indirect, incidental, special, consequential, or punitive damages.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">8. Changes to Terms</h2>
          <p className="text-indigo-900 mb-3">
            We reserve the right to modify these terms at any time. We will provide notice of significant changes by updating the date at the top of this page or by a notification when you access the Service.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">9. Governing Law</h2>
          <p className="text-indigo-900 mb-3">
            These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which InboxAI operates, without regard to its conflict of law provisions.
          </p>
        </section>
        
        <div className="text-indigo-600 text-sm mt-8">
          <p>Last updated: June 2024</p>
        </div>
      </div>
    </div>
  );
};

export default Terms; 