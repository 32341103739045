import React from 'react';

const Privacy = () => {
  return (
    <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-pink-100 to-purple-100 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6 text-indigo-800">Privacy Policy</h1>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">1. Introduction</h2>
          <p className="text-indigo-900 mb-3">
            At InboxAI, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service. Please read this privacy policy carefully. By continuing to use our service, you consent to the data practices described in this statement.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">2. Information We Collect</h2>
          <p className="text-indigo-900 mb-3">
            <strong>Personal Information:</strong> We collect information that you provide directly to us, such as when you create an account (email address), update your profile, or communicate with us.
          </p>
          <p className="text-indigo-900 mb-3">
            <strong>Usage Data:</strong> We collect information about your interactions with the service, such as the features you use, the time and duration of your activities, and the content you generate or input.
          </p>
          <p className="text-indigo-900 mb-3">
            <strong>Device Information:</strong> We may collect information about the device you use to access our service, including device type, operating system, and browser type.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">3. How We Use Your Information</h2>
          <p className="text-indigo-900 mb-3">
            We use the information we collect to:
          </p>
          <ul className="list-disc pl-6 text-indigo-900 mb-3">
            <li>Provide, maintain, and improve our services</li>
            <li>Process transactions and send related information</li>
            <li>Send you technical notices, updates, security alerts, and support messages</li>
            <li>Respond to your comments, questions, and requests</li>
            <li>Develop new products and services</li>
            <li>Monitor and analyze trends, usage, and activities in connection with our service</li>
            <li>Personalize your experience</li>
          </ul>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">4. Sharing of Information</h2>
          <p className="text-indigo-900 mb-3">
            We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties except in the following circumstances:
          </p>
          <ul className="list-disc pl-6 text-indigo-900 mb-3">
            <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf</li>
            <li>In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process</li>
            <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of InboxAI or others</li>
            <li>In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company</li>
            <li>With your consent or at your direction</li>
          </ul>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">5. Data Security</h2>
          <p className="text-indigo-900 mb-3">
            We take reasonable measures to help protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. However, no internet or email transmission is ever fully secure or error-free.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">6. Your Choices</h2>
          <p className="text-indigo-900 mb-3">
            <strong>Account Information:</strong> You may update, correct, or delete your account information at any time by logging into your account settings. If you wish to delete your account, please contact us.
          </p>
          <p className="text-indigo-900 mb-3">
            <strong>Cookies:</strong> Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">7. Children's Privacy</h2>
          <p className="text-indigo-900 mb-3">
            Our service is not directed to children under 13, and we do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13, we will delete that information.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">8. Changes to this Privacy Policy</h2>
          <p className="text-indigo-900 mb-3">
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the effective date.
          </p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3 text-indigo-700">9. Contact Us</h2>
          <p className="text-indigo-900 mb-3">
            If you have any questions about this Privacy Policy, please contact us at: 
            <a href="mailto:support@theinboxai.com" className="text-indigo-600 hover:underline"> support@theinboxai.com</a>
          </p>
        </section>
        
        <div className="text-indigo-600 text-sm mt-8">
          <p>Last updated: June 2024</p>
        </div>
      </div>
    </div>
  );
};

export default Privacy; 