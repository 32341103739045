import React, { useState, useEffect } from 'react';
import { Input } from './ui/input';
import { Button } from './ui/button';
import confetti from 'canvas-confetti';
import axios from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';

const Auth = ({ setIsAuthenticated }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check URL parameters for signup indication
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('signup') === 'true') {
      setIsLogin(false);
    } else {
      setIsLogin(true);
    }
    
    const interval = setInterval(checkTokenExpiration, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    try {
      console.log('Starting authentication process:', isLogin ? 'login' : 'registration');
      const endpoint = isLogin ? 'login' : 'register';
      console.log('Using endpoint:', endpoint);
      console.log('Current API URL:', window.REACT_APP_API_URL);
      
      // The interceptor will automatically add /api/ to the URL
      const response = await axios.post(endpoint, { email, password });
      
      console.log('Authentication response:', response.status);
      
      const { token } = response.data;
      localStorage.setItem('token', token);
      
      // If this was a new registration, redirect to onboarding flow
      if (!isLogin) {
        console.log('New registration detected, setting up for onboarding flow');
        // Store a flag to indicate this is a new user
        localStorage.setItem('newUser', 'true');
        
        // Call the parent component's authentication handler
        setIsAuthenticated(true);
        
        // Trigger confetti for visual feedback
        fireConfetti();
        
        // Short delay to ensure the state is updated before navigation
        setTimeout(() => {
          console.log('Redirecting to onboarding flow');
          navigate('/onboarding');
        }, 100);
      } else {
        console.log('Login successful, returning to app');
        setIsAuthenticated(true);
        fireConfetti();
      }
    } catch (error) {
      console.error('Auth error details:', error);
      
      // Get specific error message from axios response
      const errorMessage = error.response?.data?.error || 
                          error.message || 
                          'An error occurred during authentication';
      
      console.error('Auth error message:', errorMessage);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        if (decodedToken.exp * 1000 < Date.now()) {
          console.log('Token expired, logging out');
          localStorage.removeItem('token');
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error parsing token:', error);
        localStorage.removeItem('token');
        setIsAuthenticated(false);
      }
    }
  };

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-pink-100 to-purple-100">
      <div className="w-full max-w-md space-y-8 bg-white p-8 rounded-2xl shadow-2xl">
        <div>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-indigo-800">
            {isLogin ? 'Sign in to your account' : 'Create a new account'}
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <Input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              disabled={isLoading}
            />
            <Input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              disabled={isLoading}
            />
          </div>
  
          {error && (
            <div className="bg-red-100 text-red-700 px-4 py-3 rounded-md" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
  
          <div>
            <Button
              type="submit"
              className="w-full bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={isLoading}
            >
              {isLoading ? 'Please wait...' : (isLogin ? 'Sign in' : 'Sign up')}
            </Button>
          </div>
        </form>
        <div className="text-center">
          <Button
            variant="link"
            onClick={() => setIsLogin(!isLogin)}
            className="font-medium text-indigo-600 hover:text-indigo-500"
            disabled={isLoading}
          >
            {isLogin ? "Don't have an account? Sign up" : 'Already have an account? Sign in'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Auth;
