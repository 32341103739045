import React from 'react';
import { Button } from '../ui/button';

const WelcomeStep = ({ onNext, onSkip }) => {
  return (
    <div className="flex flex-col items-center justify-center px-4 py-12 text-center">
      {/* Skip button */}
      <button 
        onClick={onSkip}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
      >
        Skip
      </button>
      
      <h1 className="text-4xl font-bold mb-6 text-indigo-800">Welcome to InboxAI</h1>
      <div className="w-24 h-1 bg-gradient-to-r from-pink-500 to-indigo-500 mx-auto mb-10 rounded-full"></div>
      
      <div className="mb-10 max-w-2xl">
        <h2 className="text-2xl font-semibold mb-6 text-indigo-700">AI Email Content Creator</h2>
        <p className="text-lg text-gray-700 mb-6">
          InboxAI helps you craft perfect email content with AI. Create professional, engaging messages 
          in seconds and paste them into your preferred email client.
        </p>
      </div>
      
      <div className="bg-indigo-50 rounded-xl p-6 mb-8 max-w-md shadow-sm">
        <div className="flex items-center mb-4">
          <div className="bg-gray-200 rounded-full p-2 mr-4">
            <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
            </svg>
          </div>
          <div className="text-left">
            <h3 className="font-semibold text-indigo-800">Quick Setup</h3>
            <p className="text-gray-600">Let's set up your experience in just a few steps</p>
          </div>
        </div>
      </div>
      
      <Button onClick={onNext} className="px-6 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-md">
        Get Started
      </Button>
    </div>
  );
};

export default WelcomeStep; 