import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import confetti from 'canvas-confetti';

const Button = ({ children, to, className, onClick }) => (
  <Link to={to} className={`inline-block px-6 py-3 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 transition-colors ${className}`} onClick={onClick}>
    {children}
  </Link>
);

const LandingPage = () => {
  useEffect(() => {
    // Trigger confetti when the component mounts
    fireConfetti();
  }, []);

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-pink-100 to-purple-100">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <h1 className="text-2xl font-bold text-indigo-600">InboxAI</h1>
            <nav>
              <ul className="flex space-x-4">
                <li><a href="#features" className="text-indigo-600 hover:text-indigo-800">Features</a></li>
                <li><a href="#pricing" className="text-indigo-600 hover:text-indigo-800">Pricing</a></li>
                <li><Link to="/auth" className="text-indigo-600 hover:text-indigo-800">Log in</Link></li>
                <li><Link to="/auth?signup=true" className="text-indigo-600 hover:text-indigo-800">Sign Up</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <main>
        <section className="py-20 bg-gradient-to-r from-pink-100 to-purple-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl font-bold text-center mb-8 text-indigo-800">Unlock Opportunities with Every Email</h2>
            <p className="text-xl text-center mb-8 text-indigo-700">One perfectly crafted email can change your career, close a major deal, or secure that dream partnership.</p>
            <p className="text-lg text-center mb-12 text-indigo-600">InboxAI empowers you to write compelling, professional emails in seconds, with built-in grammar checking and smart reply suggestions.</p>
            <div className="text-center">
              <Button to="/auth?signup=true" className="text-lg" onClick={fireConfetti}>Sign Up Now</Button>
            </div>
          </div>
        </section>

        <section className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h3 className="text-2xl font-bold text-center mb-8 text-indigo-800">Imagine the Possibilities:</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-yellow-100 p-6 rounded-lg">
                <h4 className="text-xl font-semibold mb-4 text-yellow-800">Career Advancement</h4>
                <p className="text-yellow-700">Land your dream job or secure that promotion with perfectly tailored, error-free outreach emails.</p>
              </div>
              <div className="bg-green-100 p-6 rounded-lg">
                <h4 className="text-xl font-semibold mb-4 text-green-800">Sales Breakthroughs</h4>
                <p className="text-green-700">Close more deals with persuasive, personalized emails and quick, context-aware replies.</p>
              </div>
              <div className="bg-blue-100 p-6 rounded-lg">
                <h4 className="text-xl font-semibold mb-4 text-blue-800">Influencer Partnerships</h4>
                <p className="text-blue-700">Secure brand deals and collaborations with compelling, grammatically flawless pitches that stand out.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="py-20 bg-gradient-to-r from-orange-100 to-red-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-red-800">How InboxAI Empowers Your Communication</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-pink-100 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-pink-800">AI-Powered Writing</h3>
                <p className="text-pink-700">Generate professional, context-aware emails tailored to your specific needs in seconds.</p>
              </div>
              <div className="bg-purple-100 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">Grammar Checker</h3>
                <p className="text-purple-700">Ensure your emails are always error-free with our advanced AI grammar checking tool.</p>
              </div>
              <div className="bg-indigo-100 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-indigo-800">Smart Reply Creator</h3>
                <p className="text-indigo-700">Quickly generate appropriate responses to incoming emails with our AI-powered reply suggestions.</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
              <div className="bg-teal-100 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-teal-800">Curated Prompts</h3>
                <p className="text-teal-700">Access a wide variety of expert-crafted prompts for every professional scenario imaginable.</p>
              </div>
              <div className="bg-cyan-100 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-cyan-800">Email Management</h3>
                <p className="text-cyan-700">Automatically save and organize your generated emails for easy reference and follow-ups.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="pricing" className="py-20 bg-gradient-to-r from-green-100 to-blue-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-8 text-blue-800">Invest in Your Communication Success</h2>
            <p className="text-xl text-center mb-12 text-blue-700">Choose a plan that fits your ambition. Every email is an opportunity – make each one count.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-3xl mx-auto">
              <div className="bg-purple-50 p-8 rounded-lg shadow-md">
                <h3 className="text-2xl font-bold mb-4 text-purple-800">Monthly Plan</h3>
                <p className="text-3xl font-bold mb-4 text-purple-700">$9.99 <span className="text-base font-normal">/month</span></p>
                <ul className="mb-8 space-y-2 text-purple-600">
                  <li>✓ Unlimited AI-generated emails</li>
                  <li>✓ Access to all curated prompts</li>
                  <li>✓ Email saving and organization</li>
                  <li>✓ Advanced grammar checker</li>
                  <li>✓ AI-powered reply creator</li>
                </ul>
              </div>
              <div className="bg-indigo-50 p-8 rounded-lg shadow-md">
                <h3 className="text-2xl font-bold mb-4 text-indigo-800">Yearly Plan</h3>
                <p className="text-3xl font-bold mb-4 text-indigo-700">$4.17 <span className="text-base font-normal">/month</span></p>
                <p className="text-sm text-indigo-600 mb-4">Billed annually at $49.99/year</p>
                <ul className="mb-8 space-y-2 text-indigo-600">
                  <li>✓ All Monthly Plan features</li>
                  <li>✓ Advanced grammar checker</li>
                  <li>✓ AI-powered reply creator</li>
                  <li>✓ Save 58% compared to monthly</li>
                  <li>✓ Lock in your success for the year</li>
                </ul>
              </div>
            </div>
            <div className="text-center mt-12">
              <Button to="/auth?signup=true" className="text-lg" onClick={fireConfetti}>Sign Up Now</Button>
            </div>
          </div>
        </section>

        <section className="py-16 bg-gradient-to-r from-yellow-100 to-orange-100">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold mb-8 text-orange-800">Don't Let Another Opportunity Slip Away</h2>
            <p className="text-xl mb-8 text-orange-700">Your next big break could be just one email away. With InboxAI's writing assistance, grammar checking, and smart replies, ensure every message you send is optimized for success.</p>
            <Button to="/auth?signup=true" className="text-lg" onClick={fireConfetti}>Sign Up Now</Button>
          </div>
        </section>
      </main>

      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm text-indigo-500">&copy; 2024 InboxAI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
