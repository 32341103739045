import axios from 'axios';

// Get the base API URL from environment or window global, or use default
const API_URL = process.env.REACT_APP_API_URL || window.REACT_APP_API_URL || 'http://localhost';

// Create an Axios instance with base URL properly configured
const axiosInstance = axios.create({
  baseURL: API_URL,  // Use just the base URL without /api
});

console.log('Axios baseURL configured as:', axiosInstance.defaults.baseURL);

// Add request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Re-check API URL in case it was set after initialization
    if (window.REACT_APP_API_URL && axiosInstance.defaults.baseURL !== window.REACT_APP_API_URL) {
      console.log('Updating Axios baseURL to:', window.REACT_APP_API_URL);
      axiosInstance.defaults.baseURL = window.REACT_APP_API_URL;
    }
    
    // Check if the baseURL already contains /api
    const baseContainsApi = axiosInstance.defaults.baseURL.includes('/api');
    
    // Only add /api/ if:
    // 1. The URL doesn't already start with /api/ or api/
    // 2. The baseURL doesn't already contain /api
    if (!baseContainsApi && !config.url.startsWith('/api/') && !config.url.startsWith('api/')) {
      console.log('Adding /api/ prefix to URL:', config.url);
      config.url = `/api/${config.url}`;
    }
    
    console.log('Making API request to:', axiosInstance.defaults.baseURL + config.url);
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    console.log('Received successful response from:', response.config.url);
    return response;
  },
  (error) => {
    console.error('Response error:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error data:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received. Request:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance; 