import React from 'react';
import { Button } from '../ui/button';

const ReviewsStep = ({ onNext, onBack, onSkip }) => {
  const reviews = [
    {
      name: "Sarah J.",
      role: "Marketing Manager",
      text: "InboxAI has transformed my email workflow. I can create professional emails in seconds that used to take me 15 minutes each!",
      avatar: "https://randomuser.me/api/portraits/women/32.jpg"
    },
    {
      name: "Michael T.",
      role: "Sales Director",
      text: "The templates are fantastic and the AI understands exactly what I need. My client responses are faster and more effective.",
      avatar: "https://randomuser.me/api/portraits/men/45.jpg"
    },
    {
      name: "Priya K.",
      role: "Product Manager",
      text: "I was skeptical at first, but InboxAI has genuinely improved my communication with stakeholders. Worth every penny!",
      avatar: "https://randomuser.me/api/portraits/women/63.jpg"
    }
  ];

  return (
    <div className="flex flex-col h-full relative">
      {/* Skip button */}
      <button 
        onClick={onSkip}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
      >
        Skip
      </button>
      
      {/* Back button */}
      <button 
        onClick={onBack}
        className="absolute top-4 left-4 p-2 text-gray-500 hover:text-gray-700"
        aria-label="Go back"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
      
      <h2 className="text-3xl font-bold mb-8 text-center text-indigo-800">What Our Users Say</h2>
      
      <div className="grid md:grid-cols-3 gap-6 flex-grow">
        {reviews.map((review, index) => (
          <div key={index} className="bg-white p-6 rounded-xl shadow-sm border border-indigo-100 flex flex-col">
            <div className="flex items-center mb-4">
              <img 
                src={review.avatar} 
                alt={`${review.name} avatar`} 
                className="w-12 h-12 rounded-full mr-4 object-cover border-2 border-indigo-100"
              />
              <div>
                <h3 className="font-semibold text-indigo-800">{review.name}</h3>
                <p className="text-sm text-indigo-600">{review.role}</p>
              </div>
            </div>
            <div className="flex mb-3">
              {[1, 2, 3, 4, 5].map((star) => (
                <svg key={star} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
              ))}
            </div>
            <p className="text-gray-700 italic flex-grow">{review.text}</p>
          </div>
        ))}
      </div>
      
      <div className="mt-8 flex justify-center">
        <Button onClick={onNext} className="px-6 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-md">
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ReviewsStep; 