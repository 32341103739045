import React, { useEffect, useState } from 'react';

const LoadingStep = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [statusMessage, setStatusMessage] = useState('Setting up your content library...');

  useEffect(() => {
    // Simulate progress updates
    const interval = setInterval(() => {
      setLoadingProgress(prev => {
        if (prev >= 98) {
          clearInterval(interval);
          return 100;
        }
        return prev + 10;
      });
    }, 400);

    // Update status messages
    const messages = [
      { progress: 30, message: 'Setting up your content library...' },
      { progress: 50, message: 'Preparing your favorite templates...' },
      { progress: 70, message: 'Configuring AI models...' },
      { progress: 90, message: 'Almost ready...' }
    ];

    const messageInterval = setInterval(() => {
      const currentProgress = loadingProgress;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (currentProgress >= messages[i].progress) {
          setStatusMessage(messages[i].message);
          break;
        }
      }

      if (currentProgress >= 100) {
        clearInterval(messageInterval);
      }
    }, 500);

    return () => {
      clearInterval(interval);
      clearInterval(messageInterval);
    };
  }, []);

  return (
    <div className="w-full max-w-4xl p-8 mx-auto bg-white rounded-xl shadow-xl min-h-[70vh] flex flex-col items-center justify-center">
      <div className="mb-8 text-center">
        <div className="w-20 h-20 bg-indigo-100 rounded-full flex items-center justify-center mx-auto mb-6">
          <svg className="animate-spin w-10 h-10 text-indigo-600" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        <h2 className="text-3xl font-bold mb-2 text-indigo-800">Setting Up Your Account</h2>
        <p className="text-lg text-indigo-600 mb-8">{statusMessage}</p>
      </div>

      <div className="w-full max-w-md bg-gray-200 rounded-full h-4 mb-6">
        <div 
          className="bg-gradient-to-r from-indigo-500 to-purple-500 h-4 rounded-full transition-all duration-300 ease-out"
          style={{ width: `${loadingProgress}%` }}
        ></div>
      </div>

      <p className="text-center text-indigo-600 font-semibold">{loadingProgress}% Complete</p>
    </div>
  );
};

export default LoadingStep; 