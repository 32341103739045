import React from 'react';
import { Button } from '../ui/button';

const TemplatesStep = ({ 
  onNext, 
  onBack,
  onSkip,
  selectedTemplates, 
  onTemplateSelect 
}) => {
  const templates = [
    {
      id: 'sales-followup',
      name: 'Sales Follow-up',
      category: 'sales',
      description: 'Follow up on a sales conversation or demo'
    },
    {
      id: 'meeting-request',
      name: 'Meeting Request',
      category: 'work',
      description: 'Request a meeting with a colleague or client'
    },
    {
      id: 'product-inquiry',
      name: 'Product Inquiry',
      category: 'sales',
      description: 'Respond to questions about your product or service'
    },
    {
      id: 'interview-followup',
      name: 'Interview Follow-up',
      category: 'work',
      description: 'Follow up after a job interview'
    },
    {
      id: 'thank-you',
      name: 'Thank You Note',
      category: 'personal',
      description: 'Express gratitude for help, gifts, or opportunities'
    },
    {
      id: 'introduction',
      name: 'Introduction Email',
      category: 'networking',
      description: 'Introduce yourself to a new contact'
    },
    {
      id: 'cold-outreach',
      name: 'Cold Outreach',
      category: 'sales',
      description: 'Reach out to a prospect for the first time'
    },
    {
      id: 'apology',
      name: 'Apology Email',
      category: 'customer-service',
      description: 'Apologize for a mistake or inconvenience'
    }
  ];

  // Category badge colors
  const categoryColors = {
    'sales': 'bg-red-100 text-red-800',
    'work': 'bg-blue-100 text-blue-800',
    'personal': 'bg-green-100 text-green-800',
    'networking': 'bg-purple-100 text-purple-800',
    'customer-service': 'bg-yellow-100 text-yellow-800'
  };

  return (
    <div className="flex flex-col h-full relative">
      {/* Skip button */}
      <button 
        onClick={onSkip}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
      >
        Skip
      </button>
      
      {/* Back button */}
      <button 
        onClick={onBack}
        className="absolute top-4 left-4 p-2 text-gray-500 hover:text-gray-700"
        aria-label="Go back"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
      
      <h2 className="text-3xl font-bold mb-4 text-center text-indigo-800">Select Your Templates</h2>
      <p className="text-center text-indigo-600 mb-8">Choose templates you're most likely to use. You can access all templates later.</p>
      
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 flex-grow overflow-y-auto p-1">
        {templates.map((template) => (
          <div
            key={template.id}
            className={`border-2 rounded-xl p-4 cursor-pointer transition-all ${
              selectedTemplates.includes(template.id)
                ? 'border-indigo-600 bg-indigo-50'
                : 'border-gray-200 hover:border-indigo-300 hover:bg-indigo-50'
            }`}
            onClick={() => onTemplateSelect(template.id)}
          >
            <div className="flex items-center mb-2">
              <div className={`w-5 h-5 rounded-full border flex items-center justify-center mr-3 ${
                selectedTemplates.includes(template.id) ? 'border-indigo-600 bg-indigo-600' : 'border-gray-400'
              }`}>
                {selectedTemplates.includes(template.id) && (
                  <svg className="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                  </svg>
                )}
              </div>
              <h3 className="font-semibold text-indigo-800">{template.name}</h3>
            </div>
            <p className="text-gray-600 text-sm pl-8">{template.description}</p>
          </div>
        ))}
      </div>
      
      <div className="mt-8 flex justify-center">
        <Button onClick={onNext} className="px-6 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-md">
          Finish Setup
        </Button>
      </div>
    </div>
  );
};

export default TemplatesStep; 