import React from 'react';
import { Button } from '../ui/button';

const FeaturesStep = ({ onNext, onBack, onSkip }) => {
  return (
    <div className="flex flex-col h-full relative">
      {/* Skip button */}
      <button 
        onClick={onSkip}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
      >
        Skip
      </button>
      
      {/* Back button */}
      <button 
        onClick={onBack}
        className="absolute top-4 left-4 p-2 text-gray-500 hover:text-gray-700"
        aria-label="Go back"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </button>
      
      <h2 className="text-3xl font-bold mb-8 text-center text-indigo-800">Key Features</h2>
      
      <div className="grid md:grid-cols-2 gap-6 flex-grow">
        <div className="bg-white p-6 rounded-xl shadow-sm border border-indigo-100 flex flex-col">
          <div className="bg-indigo-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
            <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
            </svg>
          </div>
          <h3 className="text-xl font-semibold mb-2 text-indigo-700">Smart Content Generation</h3>
          <p className="text-gray-600 mb-4">Our AI crafts personalized, context-aware emails that sound like you wrote them.</p>
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-sm border border-indigo-100 flex flex-col">
          <div className="bg-indigo-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
            <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
          </div>
          <h3 className="text-xl font-semibold mb-2 text-indigo-700">Save Time</h3>
          <p className="text-gray-600 mb-4">Generate professional emails in seconds instead of spending minutes or hours writing.</p>
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-sm border border-indigo-100 flex flex-col">
          <div className="bg-indigo-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
            <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
            </svg>
          </div>
          <h3 className="text-xl font-semibold mb-2 text-indigo-700">Templates Library</h3>
          <p className="text-gray-600 mb-4">Choose from our growing library of email templates for any professional scenario.</p>
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-sm border border-indigo-100 flex flex-col">
          <div className="bg-indigo-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
            <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7v8a2 2
0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"></path>
            </svg>
          </div>
          <h3 className="text-xl font-semibold mb-2 text-indigo-700">One-Click Copy</h3>
          <p className="text-gray-600 mb-4">Copy your AI-generated content with a single click and paste it into your email client.</p>
        </div>
      </div>
      
      <div className="mt-8 flex justify-center">
        <Button onClick={onNext} className="px-6 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-md">
          Continue
        </Button>
      </div>
    </div>
  );
};

export default FeaturesStep; 