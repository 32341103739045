import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import confetti from 'canvas-confetti';
import { getApiEndpoint } from '../utils/api';

// Don't initialize Stripe immediately, we'll do it in the component
const getStripe = (key) => {
  if (!key) {
    console.error('Stripe key is missing or undefined');
    return null;
  }
  return loadStripe(key);
};

const SubscriptionModal = ({ onClose, onSubscriptionUpdate }) => {
  const [selectedPlan, setSelectedPlan] = useState('yearly');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDevMode] = useState(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1');
  const [isTestMode, setIsTestMode] = useState(false);

  // Log environment info on mount
  useEffect(() => {
    console.log('Environment check in SubscriptionModal:');
    console.log('- NODE_ENV:', process.env.NODE_ENV);
    console.log('- API URL:', process.env.REACT_APP_API_URL || window.REACT_APP_API_URL);
    console.log('- Development mode:', isDevMode);
    
    // Check if using test mode based on URL or environment
    const forcedTestMode = window.location.search.includes('test_mode=true');
    setIsTestMode(forcedTestMode || isDevMode);
    
    if (forcedTestMode) {
      console.log('Test mode forced via URL parameter');
    }
  }, [isDevMode]);

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const handleSubscribe = async () => {
    setIsLoading(true);
    setError('');
    
    try {
      // Step 1: Select the price ID based on environment
      // TEST MODE PRICE IDs (use only in development):
      const TEST_YEARLY_PRICE_ID = 'price_1PhNpSHAfivlkvgPhtZdgGUs';
      const TEST_MONTHLY_PRICE_ID = 'price_1PifSeHAfivlkvgPgTMXSAkk';
      
      // LIVE MODE PRICE IDs (use in production):
      const LIVE_YEARLY_PRICE_ID = 'price_1Pis9iHAfivlkvgP4kqn2vWM';  // LIVE equivalent of yearly price
      const LIVE_MONTHLY_PRICE_ID = 'price_1Pis9wHAfivlkvgPlnwgoOBF';  // LIVE equivalent of monthly price
      
      // Determine if we're using test mode
      const usingTestMode = isTestMode || isDevMode;
      console.log('[Subscription] Using test mode:', usingTestMode);
      
      // Select price ID based on selected plan and environment
      let priceId;
      if (usingTestMode) {
        // Use test mode prices
        priceId = selectedPlan === 'yearly' ? TEST_YEARLY_PRICE_ID : TEST_MONTHLY_PRICE_ID;
      } else {
        // Use live mode prices
        priceId = selectedPlan === 'yearly' ? LIVE_YEARLY_PRICE_ID : LIVE_MONTHLY_PRICE_ID;
      }
      
      console.log('[Subscription] Using price ID:', priceId);
      
      // Step 2: Create checkout session via API
      console.log('[Subscription] Creating checkout session...');
      
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await axios.post(getApiEndpoint('create-checkout-session'), 
        { priceId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      // Extract session data
      const { sessionId, url } = response.data;
      console.log('[Subscription] Received session ID:', sessionId);
      console.log('[Subscription] Received checkout URL:', url);
      
      // Don't even attempt to use Stripe SDK for redirect - use direct URL only
      if (url) {
        console.log('[Subscription] Redirecting directly to Stripe checkout URL');
        // Show confetti before redirect
        fireConfetti();
        // Small delay to allow confetti to show
        setTimeout(() => {
          window.location.href = url;
        }, 500);
      } else {
        throw new Error('No checkout URL returned from server');
      }
    } catch (error) {
      console.error('[Subscription] Error:', error);
      
      // Create a detailed error message
      let errorMessage = 'Failed to start subscription process. ';
      
      if (error.response) {
        console.error('[Subscription] Response error data:', error.response.data);
        console.error('[Subscription] Response error status:', error.response.status);
        errorMessage += `Server responded with status ${error.response.status}.`;
      } else if (error.request) {
        console.error('[Subscription] No response received. Request:', error.request);
        errorMessage += 'No response received from server.';
      } else {
        console.error('[Subscription] Error message:', error.message);
        errorMessage += error.message;
      }
      
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* Floating close button - positioned in the corner of the screen */}
      <button 
        onClick={onClose}
        className="fixed top-4 right-4 z-[200] bg-white w-10 h-10 rounded-full flex items-center justify-center shadow-lg border-2 border-indigo-200 text-indigo-600 hover:bg-indigo-50"
        aria-label="Close modal"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      
      {/* Modal overlay */}
      <div className="fixed inset-0 bg-indigo-900 bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        {/* Modal content - fixed height without scrolling */}
        <div className="bg-gradient-to-r from-indigo-50 to-purple-50 rounded-lg p-5 w-full max-w-md relative shadow-2xl border border-indigo-200">
          <h2 className="text-2xl font-bold mb-1 text-indigo-800 text-center">Unlock Premium</h2>
          <div className="w-12 h-1 bg-gradient-to-r from-pink-500 to-indigo-500 mx-auto mb-2 rounded-full"></div>
          
          {/* Condensed feature list */}
          <div className="grid grid-cols-2 gap-2 mb-3">
            <div className="flex items-start text-indigo-700 bg-white p-2 rounded-lg shadow-sm">
              <svg className="w-4 h-4 mr-1 mt-0.5 text-green-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <div className="text-sm">
                <span className="font-semibold">Unlimited Emails</span>
              </div>
            </div>
            <div className="flex items-start text-indigo-700 bg-white p-2 rounded-lg shadow-sm">
              <svg className="w-4 h-4 mr-1 mt-0.5 text-green-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <div className="text-sm">
                <span className="font-semibold">Advanced AI</span>
              </div>
            </div>
            <div className="flex items-start text-indigo-700 bg-white p-2 rounded-lg shadow-sm">
              <svg className="w-4 h-4 mr-1 mt-0.5 text-green-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <div className="text-sm">
                <span className="font-semibold">Original Content</span>
              </div>
            </div>
            <div className="flex items-start text-indigo-700 bg-white p-2 rounded-lg shadow-sm">
              <svg className="w-4 h-4 mr-1 mt-0.5 text-green-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <div className="text-sm">
                <span className="font-semibold">Priority Support</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl p-3 mb-3 shadow-md">
            <div className="flex gap-3">
              <div 
                className={`flex-1 border-2 rounded-lg p-2 cursor-pointer transition-all ${
                  selectedPlan === 'monthly' 
                    ? 'border-indigo-600 bg-indigo-50' 
                    : 'border-indigo-300 hover:border-indigo-400 hover:bg-indigo-50'
                }`}
                onClick={() => setSelectedPlan('monthly')}
              >
                <div className="font-semibold text-indigo-800 text-sm">Monthly</div>
                <div className="text-lg font-bold text-indigo-700">$9.99</div>
                <div className="text-xs text-indigo-600">per month</div>
              </div>
              <div 
                className={`flex-1 border-2 rounded-lg p-2 cursor-pointer transition-all ${
                  selectedPlan === 'yearly' 
                    ? 'border-indigo-600 bg-indigo-50' 
                    : 'border-indigo-300 hover:border-indigo-400 hover:bg-indigo-50'
                } relative overflow-hidden`}
                onClick={() => setSelectedPlan('yearly')}
              >
                <div className="absolute -top-0.5 -right-0.5 bg-gradient-to-r from-pink-500 to-indigo-600 text-white text-xs font-semibold px-2 py-0.5 rounded-bl-md">
                  Save 58%
                </div>
                <div className="font-semibold text-indigo-800 text-sm mt-1">Yearly</div>
                <div className="text-lg font-bold text-indigo-700">$4.17</div>
                <div className="text-xs text-indigo-600">per month</div>
                <div className="mt-1 bg-green-100 text-green-800 text-xs font-medium px-2 py-1 rounded-full inline-flex items-center">
                  <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0V5z" clipRule="evenodd" />
                  </svg>
                  3-day free trial
                </div>
              </div>
            </div>
            
            {/* Free trial info message */}
            {selectedPlan === 'yearly' && (
              <div className="mt-2 p-2 bg-blue-50 border border-blue-100 rounded-md text-xs text-blue-700">
                <p className="flex items-center">
                  <svg className="w-4 h-4 mr-1 text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path>
                  </svg>
                  Try all premium features free for 3 days! You won't be charged until your trial ends, and you can cancel anytime.
                </p>
              </div>
            )}
          </div>

          {/* Error message - keep compact */}
          {error && <div className="text-red-500 text-xs mb-2 bg-red-50 p-1 rounded-md">{error}</div>}
          
          {/* State indicators - keep compact */}
          {isDevMode && (
            <div className="mb-2 text-xs text-amber-600 p-1 bg-amber-50 rounded-md">
              <strong>Dev Mode:</strong> Using test environment.
            </div>
          )}

          {/* Submit Button */}
          <button
            type="button"
            onClick={handleSubscribe}
            disabled={isLoading}
            className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-2 rounded-lg hover:from-indigo-700 hover:to-purple-700 transition-all disabled:opacity-50 font-medium text-lg shadow-md"
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
                Processing...
              </>
            ) : selectedPlan === 'yearly' ? (
              'Start Free Trial'
            ) : (
              'Upgrade to Premium'
            )}
          </button>
          
          <div className="text-center text-xs text-indigo-500 mt-2">
            Cancel anytime. No hidden fees.
          </div>
          
          <div className="flex justify-center text-xs text-indigo-600 mt-2">
            <a href="/terms" className="hover:underline mx-2">Terms</a>
            <a href="/privacy" className="hover:underline mx-2">Privacy</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionModal;
